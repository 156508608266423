<template>
  <template v-if="item.items">
    <de-accordion
      :tabs="[
        {
          id: item.label,
          title: t(item.label),
        },
      ]"
      header-icon-position="space-between"
      header-action-class="hover:tw-bg-primary-700 tw-cursor-pointer"
      header-class="!tw-py-0"
      :header-icon-class="headerIconClasses"
      :active-index="activeIndex"
    >
      <template #header>
        <div class="tw-flex tw-items-center tw-justify-between tw-w-full">
          <div class="tw-flex tw-items-center tw-w-full tw-text-left" :class="menuItemClass">
            <de-svg-icon v-if="item.icon" :name="item.icon" class="tw-text-primary-400 tw-mr-2" />
            <span class="heading-h4 xl:heading-h5"> {{ t(item.label) }}</span>
          </div>
        </div>
      </template>
      <template #content>
        <div class="tw-flex tw-flex-col tw-gap-1.5 tw--mt-1">
          <nuxt-link-locale
            v-for="child in item.items"
            :key="child.label"
            :to="child.to"
            active-class="tw-bg-primary-700"
            class="heading-h6 tw-py-2 tw-pl-8 hover:tw-bg-primary-700 tw-cursor-pointer"
            @click="$emit('navigate')"
          >
            {{ t(child.label, 2) }}
          </nuxt-link-locale>
        </div>
      </template>
    </de-accordion>
  </template>

  <nuxt-link-locale
    v-else
    :to="item.to"
    class="tw-flex tw-items-center tw-w-full hover:tw-bg-primary-700 tw-cursor-pointer tw-text-left"
    :class="[
      {
        'tw-bg-primary-700': isActive,
      },
      menuItemClass,
    ]"
    @click="$emit('navigate')"
  >
    <de-svg-icon v-if="item.icon" :name="item.icon" class="tw-text-primary-400 tw-mr-2" />
    <span class="heading-h4 xl:heading-h5">{{ t(item.label) }}</span>
  </nuxt-link-locale>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import DeSvgIcon from '~/shared/components/lib/svg-icon/DeSvgIcon.vue';
import type { MenuItemType } from '~/shared/types/menu-item.type';
import DeAccordion from '~/shared/components/lib/accordion/DeAccordion.vue';

const props = defineProps<{
  item: MenuItemType;
  menuItemClass?: string;
  headerIconClass?: string;
  isActive?: boolean;
}>();

defineEmits(['navigate']);

const { t } = useI18n();
const route = useRoute();

const activeIndex = computed(() => {
  if (route.fullPath.includes(props.item.to)) return 0;

  return null;
});

const headerIconClasses = computed(() => {
  const classes = ['!tw-w-450 !tw-h-450 tw-mr-5'];

  if (props.headerIconClass) classes.push(props.headerIconClass);

  return classes;
});
</script>
